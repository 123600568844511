import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "@/router";
import i18n from "@/translate";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/custom.scss";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueMask from "v-mask";
import vClickOutside from "v-click-outside";
import VueCurrencyFilter from "vue-currency-filter";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);
Vue.use(VueCurrencyFilter);
Vue.use(VueCurrencyFilter, {
  symbol: "c",
  thousandsSeparator: "  ",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
  // avoidEmptyDecimals: undefined,
});

Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.component("date-picker", DatePicker);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
axios.defaults.baseURL = "http://educonsult-api.apptest.uz/";
if (window.location.href.indexOf("http://consult.uzedu.uz") > -1) {
  axios.defaults.baseURL = "http://consult-api.uzedu.uz/";
}
if (window.location.href.indexOf("https://consult.uzedu.uz") > -1) {
  axios.defaults.baseURL = "https://consult-api.uzedu.uz/";
}
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
