import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    redirect: "/about",
    children: [
      {
        path: "about",
        name: "About",
        component: () => import("@/views/About"),
      },
      {
        path: "qabul",
        name: "Qabul",
        component: () => import("@/views/qabul"),
      },
      {
        path: "taklif",
        name: "taklif",
        component: () => import("@/views/taklif"),
      },
      {
        path: "savol_javob",
        name: "savol_javob",
        component: () => import("@/views/savol_javob"),
      },
      {
        path: "izoh/edit/id=:id",
        name: "izoh",
        component: () => import("@/views/taklif/edit"),
      },
      {
        path: "show",
        name: "show",
        component: () => import("@/views/taklif/view"),
      },
      {
        path: "CalculationKind",
        name: "CalculationKind",
        component: () => import("@/views/CalculationKind/index"),
      },
      {
        path: "Insruction",
        name: "Instruction",
        component: () => import("@/views/Instruction/index"),
      },
      {
        path: "uzasbo",
        name: "uzasbo",
        component: () => import("@/views/Instruction/uzasbo"),
      },
      {
        path: "bot",
        name: "bot",
        component: () => import("@/views/Instruction/bot"),
      },
      {
        path: "erp",
        name: "erp",
        component: () => import("@/views/Instruction/erp"),
      },
      {
        path: "school",
        name: "school",
        component: () => import("@/views/school"),
      },
      {
        path: "Qualification",
        name: "Qualification",
        component: () => import("@/views/Qualification"),
      },
      {
        path: "school",
        name: "show",
        component: () => import("@/views/school/show"),
      },
      {
        path: "Regulations/id=:id",
        name: "Regulations",
        component: () => import("@/views/Regulations"),
      },
      {
        path: "Regulation/view/id=:id",
        name: "Regulation",
        component: () => import("@/views/Regulations/view.vue"),
      },
      {
        path: "About/infoPupil",
        name: "infoPupil",
        component: () => import("@/views/About/infoPupil.vue"),
      },
      /////////////////////// alohida qabul
      // {
      //   path: "alohida_qabul",
      //   name: "alohida_qabul",
      //   component: () => import("@/views/qabul/alohida_qabul"),
      // },
      ///////////////// 5-sinf internatga qabul qilish
      // {
      //   path: "5_sinf_internatga_qabul",
      //   name: "5_sinf_internatga_qabul",
      //   component: () => import("@/views/qabul/5_sinf_internatga_qabul"),
      // },
      //////////////////// ariza olish
      // {
      //   path: "ariza_olish",
      //   name: "ariza_olish",
      //   component: () => import("@/views/qabul/ariza_olish"),
      // },
      //////////////////////// ayrim fanlar chuqur orgatiladigan
      // {
      //     path : 'ayrim_fanlar_chuqur_orgatiladigan',
      //     name : 'ayrim_fanlar_chuqur_orgatiladigan',
      //     component : () => import('@/views/qabul/ayrim_fanlar_chuqur_orgatiladigan')
      // },
      //////////////// kvota buyicha qabul qilish
      // {
      //   path: "kvota_buyicha_qabul_qilish",
      //   name: "kvota_buyicha_qabul_qilish",
      //   component: () => import("@/views/qabul/kvota_buyicha_qabul_qilsh"),
      // },
      //////////////////// horij fuqorolarni qabul qilish
      // {
      //   path: "horij_fuqorolarni_qabul_qilish",
      //   name: "horij_fuqorolarni_qabul_qilish",
      //   component: () => import("@/views/qabul/horij_fuqorolarni_qabul_qilish"),
      // },
      {
        path: "ota_onalar_tulovi",
        name: "ota_onalar_tulovi",
        component: () => import("@/views/ota_onalar_tulovi"),
      },
      //ota_onalar_tulovi
      // {
      //   path: "darslik_ijaraga_berish",
      //   name: "darslik_ijaraga_berish",
      //   component: () =>
      //     import("@/views/ota_onalar_tulovi/darslik_ijaraga_berish"),
      // },
      // {
      //   path: "darsliklar_va_chet_tili",
      //   name: "darsliklar_va_chet_tili",
      //   component: () =>
      //     import("@/views/ota_onalar_tulovi/darsliklar_va_chet_tili"),
      // },
      ////////// tolov
      // {
      //   path: "tolov",
      //   name: "tolov",
      //   component: () =>
      //     import("@/views/ota_onalar_tulovi/darslik_ijaraga_berish/tolov"),
      // },
      ////////////// bepul
      // {
      //   path: "bepul",
      //   name: "bepul",
      //   component: () =>
      //     import("@/views/ota_onalar_tulovi/darslik_ijaraga_berish/bepul"),
      // },
      //////////////Pullik talim xizmatlari
      // {
      //   path: "Pullik_talim_xizmatlari",
      //   name: "Pullik_talim_xizmatlari",
      //   component: () =>
      //     import("@/views/ota_onalar_tulovi/Pullik_talim_xizmatlari"),
      // },
      //oqishni_kochirish_va_tiklash
      {
        path: "oqishni_kochirish_va_tiklash",
        name: "Oqishni-kochirish-va-tiklash",
        component: () => import("@/views/oqishni_kochirish_va_tiklash"),
      },
      //Ota -ona tolovi -> Sport inshootlarida pukllik xizmat
      // {
      //   path: "sport_xizmati",
      //   name: "sport_xizmati",
      //   component: () => import("@/views/ota_onalar_tulovi/sport_xizmati"),
      // },
      //Ota -ona tolovi -> Sport inshootlarida pukllik xizmat
      // {
      //   path: "turli_togarak",
      //   name: "turli_togarak",
      //   component: () => import("@/views/ota_onalar_tulovi/turli_togarak"),
      // },
      //Ota -ona tolovi -> Barkamol avlod bolalar markazlarida oqiganligi uchun
      // {
      //   path: "barkamol_avlod",
      //   name: "barkamol_avlod",
      //   component: () => import("@/views/ota_onalar_tulovi/barkamol_avlod"),
      // },
      //Ota-ona tolovi ->  Umumiy orta talim muassalarining uzaytirilgan kun guruhlarida bolalar taminoti
      // {
      //   path: "umumiy_talim_uzaytirish",
      //   name: "umumiy_talim_uzaytirish",
      //   component: () =>
      //     import("@/views/ota_onalar_tulovi/umumiy_talim_uzaytirish"),
      // },
      //O'qishni ko'chirish va tiklash
      // {
      //   path: "maktabdan_maktabga",
      //   name: "maktabdan_maktabga",
      //   component: () =>
      //     import("@/views/oqishni_kochirish_va_tiklash/maktabdan_maktabga"),
      // },
      // {
      //   path: "psix_ixtisoslashgan",
      //   name: "psix_ixtisoslashgan",
      //   component: () =>
      //     import("@/views/oqishni_kochirish_va_tiklash/psix_ixtisoslashgan"),
      // },
      // {
      //   path: "sinfdan_sinfga",
      //   name: "sinfdan_sinfga",
      //   component: () =>
      //     import("@/views/oqishni_kochirish_va_tiklash/sinfdan_sinfga"),
      // },
      // {
      //   path: "ummumiy_talim_otkazish",
      //   name: "ummumiy_talim_otkazish",
      //   component: () =>
      //     import("@/views/oqishni_kochirish_va_tiklash/ummumiy_talim_otkazish"),
      // },
      // {
      //   path: "xorijiy_talim_qabul",
      //   name: "xorijiy_talim_qabul",
      //   component: () =>
      //     import("@/views/oqishni_kochirish_va_tiklash/xorijiy_talim_qabul"),
      // },
      // {
      //   path: "xorijiyga_talim_otkazish",
      //   name: "xorijiyga_talim_otkazish",
      //   component: () =>
      //     import(
      //       "@/views/oqishni_kochirish_va_tiklash/xorijiyga_talim_otkazish"
      //     ),
      // },
      //ijtimoiy_soha
      {
        path: "ijtimoiy_soha",
        name: "Ijtimoiy-Soha",
        component: () => import("@/views/ijtimoiy_soha"),
      },
      //ijtimoiy_soha - > Mehribonlik uylari faoliyati
      // {
      //   path: "mehribonlik_uylari",
      //   name: "mehribonlik_uylari",
      //   component: () => import("@/views/ijtimoiy_soha/mehribonlik_uylari"),
      // },
      //ijtimoiy_soha - > jismoniy_rivojlanish faoliyati
      // {
      //   path: "jismoniy_rivojlanish",
      //   name: "jismoniy_rivojlanish",
      //   component: () => import("@/views/ijtimoiy_soha/jismoniy_rivojlanish"),
      // },
      //ijtimoiy_soha -> O'quvchini maktab internetdan davolash profilaktika muassasasiga o'tkazish
      // {
      //   path: "maktab_internatdan_davolash",
      //   name: "maktab_internatdan_davolash",
      //   component: () =>
      //     import("@/views/ijtimoiy_soha/maktab_internatdan_davolash"),
      // },
      //ijtimoiy_soha -> Jismoniy yoki psixik rivojlanishida nuqsoni bo'lgan hamda uzoq vaqt davolanishiga muxtoj bolalarning uyda ....
      // {
      //   path: "davolashga_muxtoj_bolalar",
      //   name: "davolashga_muxtoj_bolalar",
      //   component: () =>
      //     import("@/views/ijtimoiy_soha/davolashga_muxtoj_bolalar"),
      // },
      {
        path: "rasmiy_hujjatlar",
        name: "Rasmiy-hujjatlar",
        component: () => import("@/views/rasmiy_hujjatlar"),
      },
      ///////////////////// talim soxasiga apostit quysih
      // {
      //   path: "talim_soxasiga_apostit_quyish",
      //   name: "talim_soxasiga_apostit_quyish",
      //   component: () =>
      //     import("@/views/rasmiy_hujjatlar/talim_soxasiga_apostit_quyish"),
      // },
      // {
      //   path: "attestat",
      //   name: "attestat",
      //   component: () => import("@/views/rasmiy_hujjatlar/attestat"),
      // },
      // {
      //   path: "yoqotilgan_attestat",
      //   name: "yoqotilgan_attestat",
      //   component: () => import("@/views/rasmiy_hujjatlar/yoqotilgan_attestat"),
      // },

      // {
      //   path: "rasmiy_hujjat_info",
      //   name: "Rasmiy-hujjat-Info",
      //   component: () => import("@/views/rasmiy_hujjat_info"),
      // },
      // {
      //   path: "horijiy_talim",
      //   name: "Rasmiy-hujjat-Talim",
      //   component: () => import("@/views/rasmiy_hujjat_info/horijiy_talim"),
      // },
      //Rasmiy hujjat ->akkreditasiya
      // {
      //   path: "akkreditasiya",
      //   name: "akkreditasiya",
      //   component: () => import("@/views/rasmiy_hujjatlar/akkreditasiya"),
      // },
      //Rasmiy hujjat ->eksternat togrisida
      // {
      //   path: "eksternat_togrisida",
      //   name: "eksternat_togrisida",
      //   component: () => import("@/views/rasmiy_hujjatlar/eksternat_togrisida"),
      // },
      //Rasmiy hujjat ->eksternat imtihon
      // {
      //   path: "eksternat_imtihon",
      //   name: "eksternat_imtihon",
      //   component: () => import("@/views/rasmiy_hujjatlar/eksternat_imtihon"),
      // },
      //Rasmiy hujjat ->Nodavlat talim muassasalarining chet tillarni o'rganish bo'yicha oquv kurslarini tashkil etish
      // {
      //   path: "nodavlat_oquv_kurs",
      //   name: "nodavlat_oquv_kurs",
      //   component: () => import("@/views/rasmiy_hujjatlar/nodavlat_oquv_kurs"),
      // },
      //Rasmiy hujjat ->Nodavlat talim muassasalarining chet tillarni o'rgatish faoliyatii lisenziyalash
      // {
      //   path: "nodavlat_chet_tili",
      //   name: "nodavlat_chet_tili",
      //   component: () => import("@/views/rasmiy_hujjatlar/nodavlat_chet_tili"),
      // },
      ///Mashhur arboblar
      {
        path: "jadidchilar_info",
        name: "jadidchilar_info",
        component: () => import("@/views/jadidchilar_info"),
      },
      {
        path: "imtiyozlar",
        name: "imtiyozlar",
        component: () => import("@/views/imtiyozlar"),
      },
      {
        path: "teacher",
        name: "teacher",
        component: () => import("@/views/teacher"),
      },
      // Jadidchilar_info
      {
        path: "abduqodir_shakuriy",
        name: "abduqodir_shakuriy",
        component: () => import("@/views/jadidchilar_info/abduqodir_shakuriy"),
      },
      {
        path: "abdulla_avloniy",
        name: "abdulla_avloniy",
        component: () => import("@/views/jadidchilar_info/abdulla_avloniy"),
      },
      //start
      {
        path: "muhammad_sharif",
        name: "muhammad_sharif",
        component: () => import("@/views/jadidchilar_info/muhammad_sharif"),
      },
      {
        path: "mahmudxoja_behbudiy",
        name: "mahmudxoja_behbudiy",
        component: () => import("@/views/jadidchilar_info/mahmudxoja_behbudiy"),
      },
      {
        path: "bobooxun_salimov",
        name: "bobooxun_salimov",
        component: () => import("@/views/jadidchilar_info/bobooxun_salimov"),
      },
      {
        path: "ajziy",
        name: "ajziy",
        component: () => import("@/views/jadidchilar_info/ajziy"),
      },

      {
        path: "obid_mahmudov",
        name: "obid_mahmudov",
        component: () => import("@/views/jadidchilar_info/obid_mahmudov"),
      },
      {
        path: "munavvarqori_abdurashid",
        name: "munavvarqori_abdurashid",
        component: () =>
          import("@/views/jadidchilar_info/munavvarqori_abdurashid"),
      },
      //next
      {
        path: "usmonxoja",
        name: "usmonxoja",
        component: () => import("@/views/jadidchilar_info/usmonxoja"),
      },
      {
        path: "ubaydullaxoja",
        name: "ubaydullaxoja",
        component: () => import("@/views/jadidchilar_info/ubaydullaxoja"),
      },
      {
        path: "fozilbek_qosimbekov",
        name: "fozilbek_qosimbekov",
        component: () => import("@/views/jadidchilar_info/fozilbek_qosimbekov"),
      },
      {
        path: "fitrat",
        name: "fitrat",
        component: () => import("@/views/jadidchilar_info/fitrat"),
      },
      {
        path: "cholpon",
        name: "cholpon",
        component: () => import("@/views/jadidchilar_info/cholpon"),
      },
      {
        path: "abdulvohid_burhonov",
        name: "abdulvohid_burhonov",
        component: () => import("@/views/jadidchilar_info/abdulvohid_burhonov"),
      },
      {
        path: "toshpolatbek",
        name: "toshpolatbek",
        component: () => import("@/views/jadidchilar_info/toshpolatbek"),
      },
      {
        path: "sadriddin_ayniy",
        name: "sadriddin_ayniy",
        component: () => import("@/views/jadidchilar_info/sadriddin_ayniy"),
      },
      {
        path: "abdulqodir_muhiddinov",
        name: "abdulqodir_muhiddinov",
        component: () =>
          import("@/views/jadidchilar_info/abdulqodir_muhiddinov"),
      },
      {
        path: "rahmanberdi_madazimov",
        name: "rahmanberdi_madazimov",
        component: () =>
          import("@/views/jadidchilar_info/rahmanberdi_madazimov"),
      },
      // {
      //   path: "obid_mahmudov",
      //   name: "obid_mahmudov",
      //   component: () => import("@/views/jadidchilar_info/obid_mahmudov"),
      // },
      {
        path: "ishoq",
        name: "ishoq",
        component: () => import("@/views/jadidchilar_info/ishoq"),
      },
      {
        path: "hamza",
        name: "hamza",
        component: () => import("@/views/jadidchilar_info/hamza"),
      },
      {
        path: "fayzulla_xojayev",
        name: "fayzulla_xojayev",
        component: () => import("@/views/jadidchilar_info/fayzulla_xojayev"),
      },
      {
        path: "polvonniyoz_hoji",
        name: "polvonniyoz_hoji",
        component: () => import("@/views/jadidchilar_info/polvonniyoz_hoji"),
      },
      {
        path: "majid_qori_qodiriy",
        name: "majid_qori_qodiriy",
        component: () => import("@/views/jadidchilar_info/majid_qori_qodiriy"),
      },
      {
        path: "boltihoji_sultonov",
        name: "boltihoji_sultonov",
        component: () => import("@/views/jadidchilar_info/boltihoji_sultonov"),
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: routes,
});
export default router;
